import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
    carrito:[],
    datosCompra: {
      tipoEnvio:null,
      transporte:null,
      horarioRetiro:null,
      medioPago:null,
      costoFlete:null,
      codCli:null,//POR SI EL CLIENTE DE FACTURACION ES OTRO,
    },
    listaConsultada: null,
    pantallaVista:false, //PANTALLA INICIAL -> PARA QUE SE MUESTRE SOLO UNA VEZ
  },
  mutations: {
    datosUsuario: function (state, datos){
      state.usuario = datos
    },
    editDatosUsuario: function (state, dato){
      state.usuario[`${dato[0]}`] = dato[1]
    },
    editDatosCompra: function (state, dato){
      state.datosCompra[`${dato[0]}`] = dato[1]
    },
    editarLista: function (state,lista){
      state.listaConsultada = lista
    },
    agregarAlCarrito: function (state, datos){
      state.carrito.push(datos)
    },
    editarCantCarrito: function (state,datos){
      state.carrito[datos.index].cantidad = datos.cantidad
    },
    editarPrecioCarrito: function (state,datos){
      state.carrito[datos.index].pr_vta = Number(datos.pr_vta)
      state.carrito[datos.index].pr_final = Number(datos.pr_final)
    },
    editarDisponibleCarrito: function (state,datos){
      state.carrito[datos.index].disponible = Number(datos.disponible)
    },
    borrarArtCarrito: function (state, id){
      let index = state.carrito.findIndex(i => i.id==id)
      state.carrito.splice(index,1)
    },
    borrarTodoElCarrito: function (state){
      state.carrito = []
    },
    pantallaInicialVista: function (state){
      state.pantallaVista = true
    }
  },
  actions: {
    datosUsuario: async function({commit},datos){
      commit('datosUsuario',datos)
    },
    editDatosUsuario: async function({commit},dato){
      commit('editDatosUsuario', dato)
    },
    editDatosCompra: async function({commit},dato){
      commit('editDatosCompra', dato)
    },
    editarLista : async function({commit},lista){
      commit('editarLista',lista)
    },
    agregarAlCarrito: async function({commit},datos){
      commit('agregarAlCarrito',datos)
    },
    editarCantCarrito: async function({commit},datos){
      commit('editarCantCarrito',datos)
    },
    editarPrecioCarrito: async function({commit},datos){
      commit('editarPrecioCarrito',datos)
    },
    editarDisponibleCarrito:async function({commit},datos){
      commit('editarDisponibleCarrito',datos)
    },
    borrarArtCarrito: async function({commit},id){
      commit('borrarArtCarrito',id)
    },
    borrarTodoElCarrito: async function({commit}){
      commit('borrarTodoElCarrito')
    },
    pantallaInicialVista: async function ({commit}){
      commit('pantallaInicialVista')
    }
  }
})
