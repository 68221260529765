<template>
    <div>
        <v-card elevation="0">
             <v-toolbar :color=this.$azulUnarsa rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:white" class="text-truncate">Catálogo de puntos</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div style="margin-inline: 15px;" class="mb-15 mt-7">
                <b v-if="!cargando" class="mt-1 mb-2"> Puntos disponibles: {{puntosDisponibles}}</b>
                <v-list three-line v-if="this.articulos.length>0" elevation="0">
                    <template v-for="(item, index) in articulos" >
                        
                        <v-list-item :key="item.COD_ART">
                        
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>  
                                <Imagen v-if="item.imagen" :src="item.imagen"/>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                {{item.DESC_CLIENTE}}
                                <v-list-item-subtitle><span><b>Puntos: </b><b style="color:blue-grey;font-size:17px">{{item.PUNTOS}}</b></span></v-list-item-subtitle>
                                <div style="justify-content: end; display: grid;">
                                    <v-btn class="mt-2" v-if="(carrito.findIndex(i => i==item)==-1)" color="blue-grey" @click="agregar(item)" elevation="0" outlined small dark style="max-width: 100px;"><v-icon style="font-size:20px">mdi-cart-outline</v-icon>Canjear</v-btn>
                                    <template v-if="(carrito.findIndex(i => i==item)!=-1)">
                                        <p style="color:green;margin: 0px;" class="mt-2"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                                        <v-row class="mt-3" style="margin: auto;">
                                            <v-btn color="blue-grey" fab icon small @click="cantidadMenos(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                            <v-text-field
                                            v-model="carrito[carrito.findIndex(i => i==item)].cantidad"
                                            label="Cantidad"
                                            type="number"
                                            dense
                                            flat
                                            solo
                                            color="blue-grey"
                                            hide-details="true"
                                            style="max-width: 95px;align-items: center;"
                                            @click="verDialogCantidad(carrito[carrito.findIndex(i => i==item)])"
                                            class="centered-input text-blue-grey"
                                            readonly
                                            ></v-text-field>
                                            <v-btn color="blue-grey" fab icon small @click="cantidadMas(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                        </v-row>
                                        <p class="mt-2" style="color:blue-grey;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                                    </template>
                                </div>
                            </v-list-item-content>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
                <template v-if="this.articulos.length==0 && !this.cargando">
                    <p style="text-align: center; margin-top:50px" ><v-icon>mdi-information-outline</v-icon> No hay artículos disponibles</p>
                </template>
            </div>
            <!--FOOTER---------------->
            <v-footer :color=this.$azulUnarsa padless fixed outlined class="mt-5" style="height: 57px;">
                <v-row justify="center" no-gutters>
                    <v-col cols="4" class="text-center">
                        <!-- <b style="color:white;line-height: 55px; font-size: initial;" class="ml-2">Puntos del canje: {{total}}</b> -->
                        <b style="color:white; line-height: 27px;" class="ml-2">Puntos del canje</b><br>
                        <b style="color:white;" class="ml-2">{{total}}</b>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab :color=this.$azulUnarsa elevation="20" @click="irVentas" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-end">
                        <v-btn :disabled="carrito.length==0" small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="canjear()"><v-icon color="white">mdi-cart</v-icon>Canjear</v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
         <!-- CARGANDO -->
        <v-overlay :value="cargando" class="text-center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular><br><br>
        </v-overlay>
        <!-- SNACKBAR -->
        <v-snackbar v-model="snackbar" :color="snackbarColor" text outlined class="mb-15">
            {{ snackbarMsge }}
            <template v-slot:action="{ attrs }">
                <v-btn icon :color="snackbarColor" text v-bind="attrs" @click="snackbar = false"><v-icon>{{snackbarIcon}}</v-icon></v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import moment from 'moment'
import {mapState,mapActions} from 'vuex';
export default {
    name:'CatalogoDePuntos',
    components:{Imagen},
    data(){
        return{
            articulos:[],
            cargando:false,
            puntosDisponibles: 0,
            carrito:[],
            snackbar:false,
            snackbarColor:'red',
            snackbarMsge:'',
            snackbarIcon:'mdi-close-circle',
            cliente:[]
        }
    },
    methods:{
        atras(){
            this.$router.go(-1)
        },
        irVentas(){
            this.$router.push({name: 'Home'});  
        },
        async agregar(item){
            if(this.puntosDisponibles-this.total-item.PUNTOS>=0){
                item.cantidad = 1
                this.carrito.push(item)
                await ApiServer.postUsoLog({
                    cod_cli: this.usuario.cod_cli,
                    usuario: this.usuario.nombreUsuario,
                    accion: 'CARRITO_CANJE',
                    dato: item.COD_ART
                })
            }else{
                this.snackbar=true
                this.snackbarColor='red'
                this.snackbarMsge='Puntos insuficientes'
                this.snackbarIcon='mdi-close-circle'
            }
        },
        cantidadMenos(item){
            let cant = item.cantidad - 1
            let index = this.carrito.findIndex(i=>i==item)
            if(cant>0){
               this.carrito[index].cantidad = cant
            }else{
                this.carrito.splice(index,1)
            }
        },
        cantidadMas(item){
            if(this.puntosDisponibles-this.total-item.PUNTOS>0){
                let cant = item.cantidad + 1
                if((cant*item.CANT_EMPAQ)<=item.DISPONIBLE){
                let index = this.carrito.findIndex(i=>i==item)
                this.carrito[index].cantidad = cant
                }
            }else{
                this.snackbar=true
                this.snackbarColor='red'
                this.snackbarMsge='Puntos insuficientes'
                this.snackbarIcon='mdi-close-circle'
            }
        },
        verDialogCantidad(item){

        },
        descEmpaque(item){
            let cant = this.carrito[this.carrito.findIndex(i => i==item)].cantidad
            if(cant==1){
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESCALA} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${cant} ${item.ESC_PLU} / ${cant*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${cant} ${item.ESC_PLU}`
                }
            }
        },
        async canjear(){
            this.cargando = true
            try {
                //DATOS CLIENTE
                let result = await ApiServer.getDatosCliente(this.usuario.cod_cli_usuario)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        this.cliente = result[index]
                    }else{
                        this.cliente = result[0]
                    }
                }
                //TALONARIO
                let talonario = await ApiServer.getTalonarios(284);
                await ApiServer.putTalonarios(284)//TALONARIOS.NRO_CPR + 1
                let fecha = moment().format('DD-MM-YYYY')
                fecha = fecha.replace('-','.')
                fecha = fecha.replace('-','.')
                try {
                    let cpr_id = await ApiServer.getCprIdProcedure()
                    let respCpr = await ApiServer.postCpr({
                        id_pp:cpr_id.CPR_ID,
                        cpretc:12,
                        cprclasif:0,
                        cpr_tipo:"PE",
                        pv:talonario[0].NRO,
                        nro:talonario[0].NRO_CPR,
                        cpr_letra:"X",
                        tal_id:284,
                        fecha: fecha,
                        cli_id:this.cliente.CLI_ID,
                        rz:this.cliente.RZ,
                        cuit:this.cliente.CUIT,
                        iva_id:this.cliente.IVA_ID.trim(),
                        actividad:this.cliente.IB_ACTIV_ID,
                        dom:this.cliente.DOM,
                        loc_id:this.cliente.LOC_ID,
                        loc:this.cliente.LOCALIDAD,
                        cp:this.cliente.CP,
                        prov_id:this.cliente.PROVINCIA_ID.trim(),
                        tipo_pago_id:"CO",
                        lista_id:0,
                        lista:'LISTA BASE',
                        nota_cpr:'',
                        cond_id:'1',
                        computa_iva:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N',
                        discrimina_imp:(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI')?'S':'N'
                    })
                    if(!respCpr.gdscode){

                        //INSERTO CPRDET
                        try {
                            let MTOTAL = 0
                            let MTOTALN = 0
                            let IVA21 = 0
                            let IVA105 = 0
                            for (const element of this.carrito){
                                let cprdet_id =await ApiServer.postCprDet({ //ME RETORNA EL CPRDET_ID
                                    id_pp:cpr_id.CPR_ID,
                                    art_id:element.ART_ID,
                                    cod_art:element.COD_ART,
                                    descripcion:element.DESCRIPCION,
                                    desc_cliente:element.DESC_CLIENTE,
                                    mod:element.MOD,
                                    med:element.MED,
                                    descif:element.DESC_IF,
                                    cant:(element.CANT_EMPAQ==0)?(element.cantidad*1):(element.cantidad*element.CANT_EMPAQ),
                                    escala_id:element.ESCALA_ID,
                                    escala:element.ESCALA,
                                    pr_cto_cpra:element.PR_CTO_CPRA,
                                    pr_cto:element.PR_CTO,
                                    prfinal:element.PR_FINAL,
                                    pr_vta:element.PR_VTA,
                                    dep_id_org:33,
                                    dep_id_dest:33,
                                    porc_dr:0,
                                    monto_dr:0
                                }) 
                                 //INSERTO CPRDET_CPRDET
                                let cprDetCprDet = await ApiServer.postCprDetCprDet({
                                    cprdet_id: cprdet_id.CPRDET_ID,
                                    cantidad: (element.CANT_EMPAQ==0)?(element.cantidad*1):(element.cantidad*element.CANT_EMPAQ),
                                })
                                //CHECKEO QUE EXISTA STK_ID
                                let stkId = null
                                let resultStkID = await ApiServer.getStkID(element.ART_ID)
                                if(resultStkID.length==0){
                                    let resp = await ApiServer.postStock({
                                        art_id:element.ART_ID
                                    })
                                    stkId = resp.STK_ID
                                }else{
                                    stkId = resultStkID[0].STK_ID
                                }
                                //INSERTO EL MOVIMIENTO DE STOCK
                                let fechaHora = moment().format('DD-MM-YYYY HH:mm')
                                fechaHora = fechaHora.replace('-','.')
                                fechaHora = fechaHora.replace('-','.')
                                await ApiServer.postStockMov({
                                    cpr_id: cpr_id.CPR_ID,
                                    dep_id:1,
                                    art_id:element.ART_ID,
                                    fecha_hora:fechaHora,
                                    escala_id:element.ESCALA_ID,
                                    cant:(element.CANT_EMPAQ==0)?(element.cantidad*1):(element.cantidad*element.CANT_EMPAQ),
                                    cprdet_id: cprdet_id.CPRDET_ID,
                                    stock_id:stkId,
                                    cprdet_cprdet_id: cprDetCprDet.ITEM_ID
                                })
                                //INSERTO CPRDETIMP
                                let PRNETO = element.PR_VTA
                                let PRFINAL = element.PR_FINAL
                                let IMPU=0
                                let IMP_ID=0
                                if(element.CANT_EMPAQ>1){
                                    PRNETO = element.PR_VTA*element.CANT_EMPAQ
                                    PRFINAL = element.PR_FINAL*element.CANT_EMPAQ
                                }
                                if(((PRFINAL/PRNETO)-1.21)<=0.01){
                                    IVA21=IVA21+(PRNETO*.21*(element.cantidad));
                                    IMPU=21;
                                    IMP_ID=1;
                                }
                                if(((PRFINAL/PRNETO)-1.105)<=0.01){
                                    IVA105=IVA105+(PRNETO*.105*(element.cantidad));
                                    IMPU=10.5;
                                    IMP_ID=2;
                                }
                                await ApiServer.postCprdetImp({
                                    cprdet_id:cprdet_id.CPRDET_ID,
                                    imp_id:IMP_ID,
                                    impu:IMPU,
                                    neto_mas_iva:PRNETO*element.cantidad,
                                    monto_iva:PRNETO*(IMPU/100)*(element.cantidad)
                                })
                                //SUMO LOS TOTALES
                                MTOTAL=MTOTAL+(PRFINAL)*(element.cantidad);
                                MTOTALN=MTOTALN+(PRNETO)*(element.cantidad);
    
                            }
                            //INSERTO EL PIE DEL COMPROBANTE
                            try {
                                await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'-1',
                                    porc:'0',
                                    porcentaje:'0',
                                    total_neto:MTOTALN,
                                    total:MTOTAL
                                });
                                await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'0',
                                    porc:'0',
                                    porcentaje:'0',
                                    total_neto:MTOTALN,
                                    total:MTOTAL
                                });
                                if(IVA21>=0.01){
                                    await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'1',
                                    impuesto:'IVA 21%',
                                    clasif_id:'1',
                                    porc:'21',
                                    porcentaje:'0',
                                    total_neto:IVA21,
                                    total:IVA21/1.21,
                                    });
                                }
                                if(IVA105>=0.01){
                                    await ApiServer.postCprpie({
                                    cpr_id:cpr_id.CPR_ID,
                                    impuesto_id:'2',
                                    impuesto:'IVA 10.5%',
                                    clasif_id:'1',
                                    porc:'10.5',
                                    porcentaje:'0',
                                    total_neto:IVA105,
                                    total:IVA105/1.105,
                                    }); 
                                }
                                try {
                                    await ApiServer.putCprMonto(cpr_id.CPR_ID,{monto:MTOTAL})
                                    try {
                                        //CAMBIAR EL ESTADO A CPR_EST_ID=1
                                        await ApiServer.putCprEstado(cpr_id.CPR_ID,{estado:'1'})
                                    } catch (error) {
                                        this.cargando=false
                                        console.log(error)
                                        this.snackbar=true
                                        this.snackbarMsge="Se produjo un error al cambiar estado"
                                        this.snackbarColor="error"
                                        this.snackbarIcon='mdi-close-circle'
                                    }
                                } catch (error) {
                                    this.cargando=false
                                    console.log(error)
                                    this.snackbar=true
                                    this.snackbarMsge="Se produjo un error al actualizar monto"
                                    this.snackbarColor="error"
                                    this.snackbarIcon='mdi-close-circle' 
                                }
                            } catch (error) {
                                this.cargando=false
                                console.log(error)
                                this.snackbar=true
                                this.snackbarMsge="Se produjo un error al generar cprpie"
                                this.snackbarColor="error"
                                this.snackbarIcon='mdi-close-circle'
                            }
                        } catch (error) {
                            this.cargando=false
                            console.log(error)
                            this.snackbar=true
                            this.snackbarMsge="Se produjo un error al generar cprdet"
                            this.snackbarColor="error"
                            this.snackbarIcon='mdi-close-circle'
                        }
                        this.cargando=false
                        this.snackbar=true
                        this.snackbarMsge="Pedido procesado"
                        this.snackbarColor="success"
                        this.snackbarIcon='mdi-check-circle'
                        //RESTO LOS PUNTOS DEL CLIENTE
                        await ApiServer.putPuntosCliente({
                            cod_cli:this.usuario.cod_cli,
                            puntos:Number(this.puntosDisponibles)-this.total
                        })
                        //INSERTO UN REGISTRO EN puntos_app
                        await ApiServer.postRegistroPuntos({
                            accion:'Canje',
                            cod_cli:this.usuario.cod_cli,
                            cpr:`21-${talonario[0].NRO_CPR}`,
                            puntos:this.total,
                            puntos_saldo:this.puntosDisponibles-this.total
                        })
                        this.carrito = []
                        // await ApiServer.deleteCarritoMostradorTodos(sessionStorage.getItem("usu_id"))
                        this.$router.push({name:'PedidoIngresado',params:{nroPedido:talonario[0].NRO_CPR}})
                        // localStorage.setItem('carrito', JSON.stringify([]));
                    }else{
                        this.cargando=false
                        this.snackbar=true
                        this.snackbarMsge="Se produjo un error al procesar el canje"
                        this.snackbarColor="error"
                        this.snackbarIcon='mdi-close-circle'
                    }
                } catch (error) {
                    this.cargando=false
                    console.log(error)
                    this.snackbar=true
                    this.snackbarMsge="Se produjo un error al generar cpr"
                    this.snackbarColor="error"
                    this.snackbarIcon='mdi-close-circle'
                }
            } catch (error) {
                this.cargando=false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Se produjo un error'
            }
        }
    },
    async mounted(){
        this.cargando = true
        try {
            let respPuntos = await ApiServer.getPuntosCliente(this.usuario.cod_cli)
            if(respPuntos.length>0 && respPuntos[0].puntos!='' && respPuntos[0].puntos!=null){
                this.puntosDisponibles = respPuntos[0].puntos
            }
            let result = await ApiServer.getArtCatalogo()
            result.forEach(item => {
                item.imagen=`https://loguin.com.ar/img/${item.ART_ID}.jpg?t=${(new Date()).getTime()}`  
                item.cantidad=0
            });
            this.articulos = result
         
            this.cargando = false
        } catch (error) {
            this.cargando = false
            console.log(error);
        }
    },
    computed: {
    ...mapState(['usuario']),
        total(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    total+=item.PUNTOS*item.cantidad
                });
            }
            return total
        },
    }
}
</script>

<style scoped>
    .centered-input >>> input {
        text-align: center
    }
    .cant.v-text-field--outlined >>> fieldset {
        border-color: blue-grey;
        border-width: 2px;
        height: 35px;
        align-self: start;
        margin-top: 6px
    }
</style>