<template>
    <div>
        <v-card elevation="0">
             <v-toolbar :color=this.$azulUnarsa rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:white" class="text-truncate">Mis Pedidos</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div style="margin-inline: 15px;" class="mb-15 mt-7">
                <v-list three-line v-if="this.pedidos.length>0" elevation="0">
                    <template v-for="(item, index) in pedidos" >
                        
                        <v-list-item :key="item.CPR_ID">
                        
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <template>
                                    <!-- <v-img src="default_image.png" contain max-width="160" max-height="160"></v-img> -->
                                     <Imagen v-if="item.imagen" :src="item.imagen"/>
                                </template>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-subtitle><span><b style="font-size: 19px; font-weight: 800;color:#3a3a3a">Número de {{(item.NRO==20)?'pedido':'canje'}}: </b><b style="color:blue-grey;font-size:19px">{{item.NRO_CPR}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle><span><b>RZ: </b><b style="color:blue-grey;font-size:18px">{{item.RZ}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle><span><b>Fecha: </b><b style="color:blue-grey;font-size:18px">{{item.FEC_EMISION}}</b></span></v-list-item-subtitle>
                                <v-list-item-subtitle><span><b>Estado: </b><b style="color:blue-grey;font-size:18px">{{item.estado}}</b></span></v-list-item-subtitle>
                                <v-btn color="blue-grey" text style="justify-content: flex-start;padding: 0px;" @click="verDetalle(item)">DETALLE</v-btn>
                                
                            </v-list-item-content>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
                <template v-if="this.pedidos.length==0 && !this.cargando">
                    <p style="text-align: center; margin-top:50px" ><v-icon>mdi-information-outline</v-icon> No hay pedidos</p>
                </template>
            </div>
            <!--FOOTER---------------->
            <v-footer :color=this.$azulUnarsa padless fixed class="mt-5" style="justify-content: end" rounded="0">
                <v-row justify="center" no-gutters style="height: 45px;">
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab :color=this.$azulUnarsa elevation="20" @click="irHome" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-end">
                        <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
        <!-- CARGANDO -->
        <v-overlay :value="cargando" class="text-center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular><br><br>
        </v-overlay>
        <!-- DIALOG DETALLE CPR -->
        <v-dialog  v-model="dialogDetalle" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card loader-height="8px" v-if="itemSelected">
                <v-toolbar :color=this.$azulUnarsa>
                    <v-btn icon dark @click="cerrarDetalle"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-card-title style="color:white" class="text-truncate">Detalle del {{(itemSelected.NRO==20)?'pedido':'canje'}} N°{{itemSelected.NRO_CPR}}</v-card-title>
                </v-toolbar>
                <div style="margin-inline: 15px;" class="mt-5 mb-12">
                    <v-list three-line v-if="this.detalleCpr.length>0" elevation="0">
                    <template v-for="(item, index) in detalleCpr" >
                        
                        <v-list-item :key="item.COD_ART">
                        
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <Imagen v-if="item.imagen" :src="item.imagen"/>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                {{item.DESC_CLIENTE}}
                            <v-list-item-subtitle><span><b>Código: </b><b style="color:blue-grey;font-size:17px">{{item.COD_ART}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Cantidad: </b><b style="color:blue-grey;font-size:17px">{{descEmpaque(item)}}</b></span></v-list-item-subtitle>
                            <v-btn :disabled="sinStock(item)" color="blue-grey" @click="verArt(item)" class="mt-2" elevation="0" outlined small dark style="max-width: 100px;">Ver Artículo</v-btn>
                            </v-list-item-content>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
            
                    </template>
                </v-list>
                </div>
                <!--FOOTER---------------->
                <v-footer :color=this.$azulUnarsa padless fixed class="mt-5" style="justify-content: end;" rounded="0">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-footer>
            </v-card>
            <!-- CARGANDO DETALLE-->
        <v-overlay :value="cargandoDetalle" class="text-center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular><br><br>
        </v-overlay>
        </v-dialog>
    </div>
</template>

<script>
import ApiServer from '../api';
import Imagen from '../components/Imagen.vue'
import moment from 'moment'
import {mapState,mapActions} from 'vuex';
export default {
    name: 'MisPedidos',
    components:{Imagen},
    data(){
        return{
            cargando:false,
            pedidos: [],
            itemSelected:null,
            dialogDetalle:false,
            detalleCpr:[],
            cargandoDetalle:false
        }
    },
    methods:{
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        irHome(){
            this.$router.push({name: 'Home'});
        },
        verArt(item){
            this.$router.push({name: 'Articulo', params: { codArt: item.COD_ART}});
        },
        async verDetalle(item){
            this.detalleCpr = []
            this.cargandoDetalle = true
            this.dialogDetalle = true
            this.itemSelected = item
            this.$router.push('#detalle')
            try {
                let result = await ApiServer.getDetallePedido(item.CPR_ID)
                if(result.length>0){
                    result.forEach(item => {
                        item.imagen=`https://loguin.com.ar/img/${item.ART_ID}.jpg?t=${(new Date()).getTime()}`
                        if(item.CANT_EMPAQ!=0){
                            item.CANT = item.CANT/item.CANT_EMPAQ
                        }
                            
                    });
                }
                this.detalleCpr = result
                this.cargandoDetalle = false
            } catch (error) {
                this.cargandoDetalle = false
                console.log(error);
            }
        },
        cerrarDetalle(){
            this.$router.back();
        },
        descEmpaque(item){//LAS CANTIDADES EN EL CPRDET ESTAN EN UNIDADES
            if(item.CANT==1){
                if(item.ESCALA != item.ESC){
                    return `${item.CANT} ${item.ESCALA} / ${item.CANT*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${item.CANT} ${item.ESCALA}`
                }
            }else{
                if(item.ESCALA != item.ESC){
                    return `${item.CANT} ${item.ESC_PLU} / ${item.CANT*item.CANT_EMPAQ} ${item.ESC}`
                }else{
                    return `${item.CANT} ${item.ESC_PLU}`
                }
            }
        },
        sinStock(item){
            if(item.CANT_EMPAQ>0){
                return item.DISPONIBLE/item.CANT_EMPAQ<=0
            }else{
                return item.DISPONIBLE<=0
            }
        },
        
    },
    async mounted(){
        try {
            this.cargando = true
            if(this.dialogDetalle == false && this.$route.hash=='#detalle'){
               this.$router.replace({ path: '/mispedidos' })
            }
            let result  = await ApiServer.getPedidosCliente(this.usuario.cod_cli)
            let resultImagenes = await ApiServer.getImagenesCprEstados()
            result.forEach(item => {
                item.FEC_EMISION = moment(item.FEC_EMISION).format('DD/MM/YYYY')
                item.imagen = null
                if(resultImagenes){
                    let index = resultImagenes.findIndex(x=>x.cpr_est_id == item.CPR_EST_ID && x.cpr_tipo_id == item.CPR_TIPO_ID.trim())
                    if(index>=0){
                        let archivo = resultImagenes[index].icono
                        item.imagen=`https://loguin.com.ar/img/rubros_unarsa/${archivo}?t=${(new Date()).getTime()}`
                        item.estado = resultImagenes[index].estado
                    }
                }
            });
            
            this.pedidos = result
            // console.log(this.pedidos);
            this.cargando = false
        } catch (error) {
            this.cargando = false
            console.log(error);
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        
    },
    watch:{
        '$route.hash'(newHash,oldHash) {
            if(newHash === '#detalle') {
                this.dialogDetalle = true
            } else if (oldHash === '#detalle'){
                this.dialogDetalle = false
            }
        }
    }
}
</script>

<style>

</style>