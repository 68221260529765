<template>
  <div>
    <v-card elevation="0" class="text-center">
            <v-toolbar :color=this.$azulUnarsa>
            <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-card-title style="color:white" class="text-truncate text-center">Detalle de compra</v-card-title>
        </v-toolbar>
        <v-card-text class="text-center mb-15">
             <v-data-table class="text-center align-center" hide-default-footer mobile-breakpoint="400" :headers="headers" :items="productos" disable-sort :items-per-page="-1">
                 <template v-slot:[`item.precio`]="{item}">
                    <p v-if="precioConIva">{{item.pr_vta | toCurrency}} <span>{{` más IVA (${porcentajeIva(item)}%)`}}</span> </p>
                    <p v-if="!precioConIva">{{item.pr_final | toCurrency}}</p>
                 </template>
                 <template v-slot:[`item.cantidad`]="{item}">
                    <p>{{descEmpaque(item)}}</p>
                 </template>
             </v-data-table>
        </v-card-text>
         <!--FOOTER---------------->
        <v-footer :color=this.$azulUnarsa padless fixed outlined class="mt-5" style="height: 57px;" rounded="0">
            <v-row justify="center" no-gutters>
                <v-col cols="4">
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab :color=this.$azulUnarsa elevation="20" @click="irVentas" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                </v-col>
                <v-col cols="4">
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name:'DetalleCompra',
    data(){
        return{
            productos: [],
            headers:[{text: 'PRODUCTO', align: 'center',value: 'producto'},{text: 'CANTIDAD', align: 'center',value: 'cantidad'},{text: 'PRECIO POR UNIDAD', align: 'center',value: 'precio'}],
        }
            
    },
    methods:{
        atras(){
            this.$router.go(-1)
        },
        irVentas(){
            this.$router.push({name: 'Home'});
        },
        descEmpaque(item){
            if(item.cantidad==1){
                if(item.escala != item.esc){
                    return `${item.cantidad} ${item.escala} / ${item.cantidad*item.cant_empaq} ${item.esc}`
                }else{
                    return `${item.cantidad} ${item.escala}`
                }
            }else{
                if(item.escala != item.esc){
                    return `${item.cantidad} ${item.esc_plu} / ${item.cantidad*item.cant_empaq} ${item.esc}`
                }else{
                    return `${item.cantidad} ${item.esc_plu}`
                }
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.PR_FINAL/item.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        }
    },
    mounted(){
        this.carrito.forEach(item => {
            this.productos.push({
                producto: item.desc_cliente,
                cantidad:item.cantidad,
                cant_empaq:item.cant_empaq,
                pr_final:Math.round(item.pr_final + item.pr_final*(item.descuento/100)),
                pr_vta:Math.round(item.pr_vta + item.pr_vta*(item.descuento/100)),
                escala:item.escala,
                esc:item.esc,
                esc_plu:item.esc_plu,
            })
        });
    },
    computed:{
        ...mapState(['usuario','carrito']),
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        }
    }
}
</script>

<style scoped>
    .v-data-table >>> thead{
        background-color: #607d8b;
        
    }
   .theme--light.v-data-table >>> .v-data-table__wrapper > table > thead > tr > th{
        color: white;
        /* align-items: center;
        display: flex; */
   }
</style>