<template>
    <div>
        <v-card elevation="0">
             <v-toolbar :color=this.$azulUnarsa>
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:white" class="text-truncate">Tipo de retiro</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                <v-row class="justify-center mt-5">
                    <v-btn-toggle v-model="opcionEnvio" mandatory background-color="blue-grey">
                        <v-btn  text width="150" :color="(opcionEnvio==0)?'white':'blue-grey'" :style="(opcionEnvio!=0)?'background-color: white;':''">envío</v-btn>
                        <v-btn  text width="150" :color="(opcionEnvio==1)?'white':'blue-grey'" :style="(opcionEnvio!=1)?'background-color: white;':''">retiro</v-btn>
                    </v-btn-toggle>
                </v-row>

                <div v-if="opcionEnvio==0" class="mt-10">
                    <h4 style="color:blue-grey" class="letra">MIS DIRECCIONES</h4>
                    
                    <v-row class="mt-5">
                        <v-col cols="2" style="display: grid;    align-items: center;">
                            <v-icon color="blue-grey" style="font-size:42px">mdi-map-marker</v-icon>
                        </v-col>
                        <v-col cols="10" style="text-align:initial">
                            <p class="mb-0 mt-0 p-0 text-h6" style="color:blue-grey">Envio</p>
                            <p class="mb-0 mt-0 p-0 text-subtitle-1" v-if="cliente">{{cliente.DOM}}</p>
                            <p class="mb-0 mt-0 p-0 text-subtitle-1" v-if="cliente">{{`${cliente.LOCALIDAD} , ${cliente.PROVINCIA}`}} , Argentina</p>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-3 ml-3" style="border-top: ridge;width: 80%;display: inline-block;"></v-divider>
                    <v-row class="mt-5">
                        <v-col>
                            <v-select
                            class="ml-3 mr-3"
                            v-model="transporteSelected"
                            :items="transportes"
                            menu-props="auto"
                            label="Transporte"
                            prepend-icon="mdi-bus"
                            color="blue-grey"
                            item-color="blue-grey"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <template v-if="envioCliente">
                        <v-card v-if="envioCliente" :style="(costoFlete==0)?'background-color: #f3c700':'background-color: #fba598'">
                            <v-row class="mt-5">
                                <!-- <v-col class="mt-2 mb-2" style="justify-content: end;display: flex;">
                                    <p :style="(costoFlete==0)?'color:#00a650':''" style="font-size:20px;font-weight: 600;margin:0px;padding:0px">Envío</p>
                                </v-col> -->
                                <v-col  class="mt-2 mb-2" style="justify-content: center;display: flex;">
                                
                                    <p v-if="precioConIva && costoFlete>0" style="font-size:20px;font-weight: 600;margin:0px;padding:0px">Envío: {{costoFlete | toCurrency}}<span style="font-size: 13px;"> más IVA (21%)</span></p>
                                    <p v-if="!precioConIva && costoFlete>0" style="font-size:20px;font-weight: 600;margin:0px;padding:0px">Envío: {{costoFlete | toCurrency}}</p>
                                    <p v-if="costoFlete==0" style="color:black;font-size:20px;font-weight: 600;margin:0px;padding:0px">Envío Gratis</p>
                                    
                                </v-col>
                            </v-row>
                        </v-card>
                    </template>
                </div>
                <div v-if="opcionEnvio==1" class="mt-10">
                    <h4 style="color:blue-grey" class="letra">SUCURSALES</h4>
                    <v-row class="mt-5">
                        <v-col cols="2" style="display: grid;align-items: center;">
                            <v-icon color="blue-grey" style="font-size:34px">mdi-basket</v-icon>
                        </v-col>
                        <v-col cols="10" style="text-align:initial;display: grid;align-items: end;">
                            <p class="mb-0 mt-0 p-0 text-h6">Deán J. Álvarez 262</p>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-3 ml-3" style="border-top: ridge;"></v-divider>
                    <div class="mt-10">
                        <v-row class="text-center justify-center">
                            <p class="letra mb-0 mt-0 p-0 text-h6" style="color:blue-grey;">Horarios de Retiro</p>
                        </v-row>
                        <v-row style="justify-content: center;">
                            <v-radio-group v-model="horarioSelected" column style="min-width: 372px;">
                                <template v-for="(item,index) in horariosDeRetiro">
                                    <v-radio color="blue-grey" :key="item" :value="item" class="mt-2 mb-2">
                                        <template v-slot:label>
                                            <v-row class="ml-1">
                                                <v-col>
                                                    <span>{{item}}</span>
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-radio>
                                    <v-divider style="margin-left: 87px;" :key="index"></v-divider>
                                </template>
                            </v-radio-group>
                        </v-row>
                    </div>
                </div>

            </v-card-text>
            <!--FOOTER---------------->
            <v-footer :color=this.$azulUnarsa padless fixed outlined class="mt-5" style="height: 57px;">
                
                <v-row justify="center" no-gutters>
                    <v-col cols="4">
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn style="bottom: 23px;" fab :color=this.$azulUnarsa elevation="20" @click="irVentas" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-btn :disabled="bloquearSiguiente" small style="height: 54px;font-size: initial;" color="white" text elevation="0" @click="siguiente()">Siguiente<v-icon color="white">mdi-chevron-right</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-footer>
        </v-card>
        <!-- ----CARGANDO------- -->
        <v-overlay :value="cargando">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import ApiServer from '../api';
import {mapState, mapActions} from 'vuex';
export default {
    name:'Envio',
    data(){
        return{
            cliente:null,
            opcionEnvio:null,
            transportes:[],
            transporteSelected:null,
            horarioSelected:null,
            horariosDeRetiro:[],
            envioCliente:null,
            cargando:false
        }
    },
    methods:{
        ...mapActions(['editDatosCompra']),
        atras(){
            this.$router.go(-1)
        },
        irVentas(){
            this.$router.push({name: 'Home'});  
        },
        siguiente(){
            this.editDatosCompra(['tipoEnvio',(this.opcionEnvio==0)?'Envio':'Retiro'])
            this.editDatosCompra(['transporte',this.transporteSelected])
            this.editDatosCompra(['horarioRetiro',this.horarioSelected])
            if(this.envioCliente){
                if(this.precioConIva){
                    this.editDatosCompra(['costoFlete',this.costoFlete*(1.21)])
                }else{
                    this.editDatosCompra(['costoFlete',this.costoFlete])
                }
            }else{
                this.editDatosCompra(['costoFlete',null])
            }
            this.$router.push({name: 'Pago'});  
        }
    },
    async mounted(){
        if(this.usuario){
            this.cargando = true
            try {
               let respTransporte = await ApiServer.getTransportesCliente(this.usuario.cod_cli)
               console.log(respTransporte);
               respTransporte.forEach(item => {
                this.transportes.push(item.NOM_FANTASIA)
               });
               this.transporteSelected = this.transportes[0]
                let result = await ApiServer.getDatosCliente(this.usuario.cod_cli)
                if(result.length>0){
                    let index = result.findIndex(x=>x.DOM_CLASIF_ID.trim()=='DE')
                    if(index>=0){
                        this.cliente = result[index]
                    }else{
                        this.cliente = result[0]
                    }
                }
                let resultEnvio = await ApiServer.getFleteCliente(this.usuario.cod_cli)
                if(resultEnvio.length>0)
                this.envioCliente = resultEnvio[0]

                let resultHorarios = await ApiServer.getHorariosRetiro()
                resultHorarios.forEach(item => {
                    this.horariosDeRetiro.push(item.descripcion)
                });
                this.cargando = false

                await ApiServer.postUsoLog({
                   cod_cli: this.usuario.cod_cli,
                   usuario: this.usuario.nombreUsuario,
                   accion: 'TIPO_DE_RETIRO',
                   dato: ''
               })
            } catch (error) {
                this.cargando = false
                console.log(error);
            }
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        bloquearSiguiente(){
            if(this.opcionEnvio==0){//ENVIO
                if(!this.transporteSelected){
                    return true
                }else{
                    return false
                }
            }else{//RETIRO
                if(!this.horarioSelected){
                    return true
                }else{
                    return false
                }
            }
        },
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
        total(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=item.pr_final*empaque*item.cantidad
                });
            }
            return total
        },
        totalSinIva(){
            let total=0
            if(this.carrito.length>0){
                this.carrito.forEach(item => {
                    let empaque = (item.cant_empaq!=0)? item.cant_empaq : 1 
                    total+=item.pr_vta*empaque*item.cantidad
                });
            }
            return total
        },
        costoFlete(){
            let total = (this.precioConIva)?this.totalSinIva:this.total
            if(this.envioCliente && this.envioCliente.envio_gratis==1){
                let minimo = (this.envioCliente.MontoMinimo/this.envioCliente.Porcentaje)*100
                if(total>=minimo){
                    return 0.00
                }else{
                    if(this.precioConIva){//LE AGREGO "más IVA" en la descripcion
                        return this.envioCliente.MontoMinimo
                    }else{
                        return (this.envioCliente.MontoMinimo*1.21)
                    }
                }
            }else if(this.envioCliente){
                let minimo = (this.envioCliente.MontoMinimo/this.envioCliente.Porcentaje)*100
                if(total>=minimo){
                    return total*(this.envioCliente.Porcentaje/100)
                }else{
                    if(this.precioConIva){//LE AGREGO "más IVA" en la descripcion
                        return this.envioCliente.MontoMinimo
                    }else{
                        return (this.envioCliente.MontoMinimo*1.21)
                    }
                }
            }else{
                return 0
            }
        }
    }
}
</script>

<style scoped>
    .letra{
        font-family: sans-serif;
        letter-spacing: 1px;
    }
</style>