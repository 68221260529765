<template>
  <div>
    <v-card elevation="0">
        <v-toolbar :color=this.$azulUnarsa rounded="0" elevation="5" class="fixed-bar">
            <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-card-title style="color:#ffff">¡Pedido Ingresado!</v-card-title>
        </v-toolbar>
        <div style="margin-inline: 15px;" class="mb-7 mt-7" >
            <v-row>
                <v-col class="d-flex justify-center">
                    <v-img
                    class="mx-2"
                    src="../assets/Principal.png"
                    max-height="50"
                    max-width="250px"
                    contain
                ></v-img>
                </v-col>
            </v-row>
        </div>
        <div style="background-color:#ef6b01">
                <v-row style="margin-left: 15px; margin:0px">
                    <v-col class="mt-5 d-flex justify-start">
                        <font-awesome-icon style="color:white;font-size: 24px;" class="mr-3" :icon="['far', 'paper-plane']" /><p style="color: white">Número de pedido {{nroPedido}}</p>
                    </v-col>
                </v-row>
                <v-row style="margin-left: 15px; margin:0px">
                    <v-col class="d-flex justify-start">
                        <v-icon style="color:white;font-size: 31px;align-items: baseline;" class="mr-3">mdi-message-processing-outline</v-icon><p style="color: white">Recordá que podés seguir tu pedido usando esta aplicación</p>
                    </v-col>
                </v-row>
        </div>
        <!--FOOTER---------------->
        <v-footer :color=this.$azulUnarsa padless fixed outlined class="mt-5" style="height: 57px;" rounded="0">
            <v-row justify="center" no-gutters>
                <v-col cols="4">
                    <v-btn small style="height: 54px;font-size: initial;" :color=this.$azulUnarsa dark block elevation="0" @click="irMisPedidos">Ir a mis pedidos</v-btn>
                </v-col>
                <v-col cols="4" class="text-center">
                    <v-btn style="bottom: 23px;" fab :color=this.$azulUnarsa elevation="20" @click="irVentas" class="my-2"><v-icon color="white">mdi-plus-thick</v-icon></v-btn>
                </v-col>
                <v-col cols="4">
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
  </div>
</template>

<script>
export default {
    name:'PedidoIngresado',
    props:{nroPedido:{Type:Number}},
    data(){
        return{

        }
    },
    methods:{
        atras(){
            this.$router.push({name: 'Home'});
        },
        irMisPedidos(){
            this.$router.push({name: 'MisPedidos'});
        },
        irVentas(){
            this.$router.push({name: 'Home'});
        }
    },
    mounted(){
        if(!this.nroPedido){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
    .fixed-bar {
        position: sticky;
        position: -webkit-sticky; /* for Safari */
        top: 0em;
        z-index: 2;
    }
</style>