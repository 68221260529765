<template>
     <div>
        <v-card elevation="0">
             <v-toolbar :color=this.$azulUnarsa rounded="0" elevation="5" class="fixed-bar">
                <v-btn icon dark @click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="color:white" class="text-truncate">Mis Cotizaciones</v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <div style="margin-inline: 15px;" class="mb-15 mt-7">
                <v-list three-line v-for="item in cotizaciones" :key="item.CPR_ID" elevation="0">
                    <v-list-item >
                    
                        <v-list-item-avatar :rounded="true" size="80px" horizontal>
                            <v-img contain src="../assets/cotizaciones.jpeg" max-width="160" max-height="160"/>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-subtitle><span><b style="font-size: 19px; font-weight: 800;color:#3a3a3a">Número de presupuesto: </b><b style="color:blue-grey;font-size:19px">{{item.NRO_CPR}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Fecha: </b><b style="color:blue-grey;font-size:18px">{{item.FEC_EMISION}}</b></span></v-list-item-subtitle>
                            <!-- <v-list-item-subtitle><span><b>Estado: </b><b style="color:blue-grey;font-size:18px">{{item.estado}}</b></span></v-list-item-subtitle> -->
                            <v-btn color="blue-grey" text style="justify-content: flex-start;padding: 0px;" @click="verDetalle(item)">DETALLE</v-btn>
                            
                        </v-list-item-content>
                    
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list>
                <template v-if="this.cotizaciones.length==0 && !this.cargando">
                    <p style="text-align: center; margin-top:50px" ><v-icon>mdi-information-outline</v-icon> No hay pedidos</p>
                </template>
            </div>
            <!--FOOTER---------------->
            <v-footer :color=this.$azulUnarsa padless fixed class="mt-5" style="justify-content: end" rounded="0">
            <v-row justify="end" no-gutters style="height: 45px;">
                <v-col cols="4" class="text-end">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-col>
            </v-row>
        </v-footer>
        </v-card>
        <!-- CARGANDO -->
        <v-overlay :value="cargando" class="text-center">
            <v-progress-circular
                indeterminate
                size="70"
                width="7"
            ></v-progress-circular><br><br>
        </v-overlay>
        <!-- DIALOG DETALLE CPR -->
        <v-dialog  v-model="dialogDetalle" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card loader-height="8px" v-if="itemSelected">
                <v-toolbar :color=this.$azulUnarsa>
                    <v-btn icon dark @click="cerrarDetalle"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-card-title style="color:white" class="text-truncate">Cotización N°{{itemSelected.NRO_CPR}}</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn small outlined dark @click="confirmarTodo">Confirmar todo</v-btn>
                </v-toolbar>
                <div style="margin-inline: 15px;" class="mt-5 mb-12">
                    <v-list three-line v-for="(item, index) in detalleCpr" :key="item.COD_ART" elevation="0">
                        <v-list-item >
                        
                            <v-list-item-avatar :rounded="true" size="80px" horizontal>
                                <Imagen v-if="item.imagen" :src="item.imagen"/>
                            </v-list-item-avatar>

                            <v-list-item-content>
                            {{(item.DESC_CLIENTE)?item.DESC_CLIENTE:item.DESCRIPCION}}
                            <v-list-item-subtitle><span><b>Código: </b><b style="color:blue-grey;font-size:17px">{{item.COD_ART}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Empaque: </b><b style="color:blue-grey;font-size:17px">{{(item.ESCALA!=item.ESC)?item.ESCALA:''}} {{item.CANT_EMPAQ}} {{item.ESC}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle><span><b>Precio contado: </b><b style="color:blue-grey;font-size:17px">{{precioArt(item) | toCurrency}}</b></span> <span v-if="precioConIva" style="color:blue-grey;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle><v-list-item-subtitle><span><b>Empaque: </b><b style="color:blue-grey;font-size:17px">{{descEmpaque(item)}}</b></span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.CANT_EMPAQ!=1"><span><b>Precio por empaque contado: </b><b style="color:blue-grey;font-size:17px">{{precioPorEmpaque(item) | toCurrency}}</b></span><span v-if="precioConIva" style="color:blue-grey;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)!=-1) && carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad>1"><span><b>Precio total contado: </b><b style="color:blue-grey;font-size:17px">{{Math.round(precioTotalItem(item)) | toCurrency}}</b></span><span v-if="precioConIva" style="color:blue-grey;font-size: 13px;">{{` más IVA (${porcentajeIva(item)}%)`}}</span></v-list-item-subtitle>
                            <div style="justify-content: end; display: grid;" class="mt-3">
                                <v-btn v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)==-1)" color="blue-grey" @click="agregar(item)" elevation="0" outlined small dark style="max-width: 100px;"><v-icon style="font-size:20px">mdi-cart-outline</v-icon>Agregar</v-btn>
                                <template v-if="(carrito.findIndex(i => i.cod_art==item.COD_ART)!=-1)">
                                    <p style="color:green;margin: 0px;" class="mt-2"><v-icon style="font-size:20px" color="green">mdi-cart-check</v-icon>Agregado al carrito</p>
                                    <v-row class="mt-3" style="margin: auto;">
                                        <v-btn color="blue-grey" fab icon small @click="cantidadMenos(item)"><v-icon>mdi-minus-thick</v-icon></v-btn>
                                        <v-text-field
                                        v-model="carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)].cantidad"
                                        label="Cantidad"
                                        type="number"
                                        dense
                                        flat
                                        solo
                                        hide-details="true"
                                        color="blue-grey"
                                        style="max-width: 95px;align-items: center;"
                                        @click="verDialogCantidad(carrito[carrito.findIndex(i => i.cod_art==item.COD_ART)])"
                                        class="centered-input text-blue-grey"
                                        readonly
                                        ></v-text-field>
                                        <v-btn color="blue-grey" fab icon small @click="cantidadMas(item)"><v-icon>mdi-plus-thick</v-icon></v-btn>
                                    </v-row>
                                    <p v-if="entregaDiferida(item)"  class="mt-2" style="color:blue-grey;text-align: center;max-width: 168px; margin: 0px;">{{ entregaDiferida(item) }} con entrega diferida</p>
                                    <p class="mt-2" style="color:blue-grey;text-align: center;max-width: 168px;">{{descEmpaque(item)}}</p>
                                </template>
                            </div>
                        </v-list-item-content>
                        
                        </v-list-item>
                        <v-divider :key="index" :inset="item.inset"></v-divider>
                </v-list>
                </div>
                <!--FOOTER---------------->
                <v-footer :color=this.$azulUnarsa padless fixed class="mt-5" style="justify-content: end;" rounded="0">
                    <v-btn @click="irCarrito" icon class="my-1 mr-2" color="white"><v-icon style="font-size:25px" color="#ffff">mdi-cart</v-icon>{{(carrito.length>0)?carrito.length:''}}</v-btn>
                </v-footer>
            </v-card>
            <!-- CARGANDO DETALLE-->
            <v-overlay :value="cargandoDetalle" class="text-center">
                <v-progress-circular
                    indeterminate
                    size="70"
                    width="7"
                ></v-progress-circular><br><br>
            </v-overlay>
        </v-dialog>
        <!-- DIALOG CANTIDAD -->
        <v-dialog v-model="dialogCantidad" max-width="300px" persistent>
            <v-card v-if="itemCantSelected">
                <v-toolbar color="#ef6b01">
                    <v-btn icon dark @click="cerrarDialogCambiarCantidad"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-card-title style="color:#ffff">Cambiar cantidad</v-card-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="mt-3" v-if="itemCantSelected">
                    <v-text-field autofocus outlined style="font-size: 20px;" v-model="carrito[carrito.findIndex(i => i.cod_art==itemCantSelected.cod_art)].cantidad" label="Cantidad" type="number" solo color="orange"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="itemCantSelected" color="orange" elevation="5" outlined @click="cambiarCantidad()" :disabled="!validarCantidad">Aceptar</v-btn>
                    <v-btn color="grey" elevation="5" @click="cerrarDialogCambiarCantidad" outlined>Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
     </div>
</template>

<script>
import ApiServer from '../api';
import {mapState,mapActions} from 'vuex'
import Imagen from '../components/Imagen.vue'
import moment from 'moment'
export default {
    name:'Cotizaciones',
    components:{Imagen},
    data(){
        return{
            cargando:false,
            cotizaciones: [],
            dialogDetalle:false,
            itemSelected:null,
            detalleCpr:[],
            cargandoDetalle:false,
            itemCantSelected:null,
            dialogCantidad:false,
            snackbar:false,
            snackbarColor:'error',
            snackbarMsge:'',
            snackbarIcon:'mdi-check-circle',
            mensajeCargando:'',
            dialogCantidad:false,
        }
    },
    methods:{
        ...mapActions(['agregarAlCarrito','editarCantCarrito','borrarArtCarrito','editarArtCarrito']),
        atras(){
            this.$router.go(-1)
        },
        irCarrito(){
            this.$router.push({name: 'Carrito'});
        },
        async verDetalle(item){
            this.detalleCpr = []
            this.cargando = true
            this.cargandoDetalle = true
            this.dialogDetalle = true
            this.itemSelected = item
            this.$router.push('#detalle')
            try {
                let result = await ApiServer.getDetallePedido(item.CPR_ID)
                for(const item of result){
                    let resultArt = await ApiServer.getArtLpr(item.COD_ART,this.usuario.listas[0].LISTA_ID,this.usuario.cli_id)
                    if(resultArt.length>0){
                        if(item.FEC_CANCEL){ 
                            let hoy=moment().format('DD-MM-YYYY')
                            let fecVenc = moment(item.FEC_CANCEL).format('DD-MM-YYYY')
                            let dif = moment(hoy,"DD-MM-YYYY").diff(moment(fecVenc,"DD-MM-YYYY"),'days')
                            if(dif>0){//ESTA VENCIDO

                                item.PR_VTA = resultArt[0].PR_VTA
                                item.PR_FINAL = resultArt[0].PR_FINAL
                                item.DESCUENTO = resultArt[0].DESCUENTO
                            }else{
                                // item.DESCUENTO = (100-(item.PR_VTA*100/resultArt[0].PR_VTA)).toFixed(2)
                                item.DESCUENTO = item.PORC_DR
                            }
                        }else{//SI ES NULL, LO TOMO COMO VENCIDO
                            item.PR_VTA = resultArt[0].PR_VTA
                            item.PR_FINAL = resultArt[0].PR_FINAL
                            item.DESCUENTO = resultArt[0].DESCUENTO
                        }
                        if(!item.PR_VTA){//SI POR ALGUN MOTIVO NO TIENE EL PR_VTA
                            item.PR_VTA = resultArt[0].PR_VTA
                        }
                        if(!item.PR_FINAL){//SI POR ALGUN MOTIVO NO TIENE EL PR_FINAL
                            item.PR_FINAL = resultArt[0].PR_FINAL
                        }
                    }
                    item.imagen=`https://loguin.com.ar/img/${item.ART_ID}.jpg?t=${(new Date()).getTime()}`
                    if(item.CANT_EMPAQ!=0){
                        item.CANT = item.CANT/item.CANT_EMPAQ
                    }
                    item.PR_CTO = item.PR_COSTO//CTO VIENE DE ARTICULOS Y COSTO DE CPRDET
                    item.ORD_REC_STR = resultArt[0].ORD_REC_STR
                    item.ETIQUETA = resultArt[0].ETIQUETA

                }
                
                this.detalleCpr = result
                this.cargando = false
                this.cargandoDetalle = false
            } catch (error) {
                this.cargando = false
                this.cargandoDetalle = false
                console.log(error);
            }
        },
        cerrarDetalle(){
            this.$router.back();
        },
        descEmpaque(item){//LAS CANTIDADES EN EL CPRDET ESTAN EN UNIDADES
            let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
           if(index!=-1){
               let cantidad = this.carrito[index].cantidad
               if(cantidad==1){
                   if(item.ESCALA != item.ESC){
                       return `${cantidad} ${item.ESCALA} / ${cantidad*item.CANT_EMPAQ} ${item.ESC}`
                   }else{
                       return `${cantidad} ${item.ESCALA}`
                   }
               }else{
                   if(item.ESCALA != item.ESC){
                       return `${cantidad} ${item.ESC_PLU} / ${cantidad*item.CANT_EMPAQ} ${item.ESC}`
                   }else{
                       return `${cantidad} ${item.ESC_PLU}`
                   }
               }
           }
        },
        sinStock(item){
            if(item.CANT_EMPAQ>0){
                return item.DISPONIBLE/item.CANT_EMPAQ<=0
            }else{
                return item.DISPONIBLE<=0
            }
        },
        entregaDiferida(item){
            let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
            if(this.carrito[index].cantidad*item.CANT_EMPAQ > item.DISPONIBLE){
                return (this.carrito[index].cantidad - item.DISPONIBLE/item.CANT_EMPAQ)
            }else{
                return false
            }
        },
        porcentajeIva(item){
            //IVA 10.5 o 21
            let x = item.PR_FINAL/item.PR_VTA
            if(Math.abs(21-(x-1)*100)<Math.abs(10.5-(x-1)*100)){
                return 21
            }else{
                return 10.5
            }
        },
        async agregar(item){
            this.cargandoDetalle = true
            try {
                let result = await ApiServer.postCarritoCliente({
                    cod_cli:this.usuario.cod_cli,
                    cod_art:item.COD_ART,
                    cantidad: (item.CANT)?item.CANT:1,
                    lpr_id:(item.LISTA_ID)?item.LISTA_ID:this.usuario.listas[0].LISTA_ID,
                    usuario: this.usuario.nombreUsuario,
                    accion:'CARRITO COTIZACION',
                    cpr:this.itemSelected.CPR_ID,
                    fec_cancel:(item.FEC_CANCEL)?moment(item.FEC_CANCEL).format('YYYY-MM-DD'):item.FEC_CANCEL
                });
                this.agregarAlCarrito({
                    id:result.insertId,
                    lpr_id:(item.LISTA_ID)?item.LISTA_ID:this.usuario.listas[0].LISTA_ID,
                    cod_art:item.COD_ART,
                    art_id:item.ART_ID,
                    desc_cliente: item.DESC_CLIENTE,
                    descripcion: item.DESCRIPCION,
                    mod:item.MOD,
                    med:item.MED,
                    desc_if:item.DESC_IF,
                    cantidad:(item.CANT)?item.CANT:1,
                    cant:item.CANT*item.CANT_EMPAQ,
                    escala_id:item.ESCALA_ID,
                    escala:item.ESCALA,
                    esc:item.ESC,
                    esc_plu:item.ESC_PLU,
                    pr_vta:item.PR_VTA,
                    pr_cto_cpra:item.PR_CTO_CPRA,
                    pr_cto:item.PR_CTO,
                    pr_final:item.PR_FINAL,
                    descuento:item.DESCUENTO,
                    disponible:item.DISPONIBLE,
                    cant_empaq:item.CANT_EMPAQ,
                    ord_rec_str:item.ORD_REC_STR,
                    ean:item.EAN,
                    imagen : `https://loguin.com.ar/img/${item.ART_ID}.jpg`,
                    etiqueta : item.etiqueta,
                    cpr:this.itemSelected.CPR_ID,
                    fec_cancel:(item.FEC_CANCEL)?moment(item.FEC_CANCEL).format('YYYY-MM-DD'):item.FEC_CANCEL
                })
                this.cargandoDetalle = false
                console.log('agregado',this.carrito);
            } catch (error) {
                this.cargandoDetalle = false
                console.log(error);
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Error'
            }
        },
        async cantidadMas(item){
            let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
            let cant = this.carrito[index].cantidad
            if((cant*item.CANT_EMPAQ<this.cantMAx(item.CANT*item.CANT_EMPAQ,item.DISPONIBLE))){
                this.cargandoDetalle = true
                try {
                    cant = cant +1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                    this.cargandoDetalle = false
                    this.componentKey++
                } catch (error) {
                    console.log(error);
                    this.cargandoDetalle = false
                    
                }
            }else{
                this.snackbar = true
                this.snackbarColor ='error'
                this.snackbarIcon = 'mdi-close-circle'
                this.snackbarMsge='Sin stock'
            }
        },
        async cantidadMenos(item){
            this.cargandoDetalle = true
            try {
                let index = this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                if(this.carrito[index].cantidad>1){
                    let cant = this.carrito[index].cantidad-1
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }else{
                    await ApiServer.deleteCarritoCliente(this.carrito[index].id)
                    this.borrarArtCarrito(this.carrito[index].id)
                }
                this.cargandoDetalle = false
            } catch (error) {
                 console.log(error);
                this.cargandoDetalle = false
            }
        },
        verDialogCantidad(item){
            this.itemCantSelected = item
            this.dialogCantidad = true
        },
        async cambiarCantidad(){
            this.dialogCantidad = false
            this.cargandoDetalle = true
            try {
                let cant = Number(this.itemCantSelected.cantidad)
                if(cant>0 && (cant*this.itemCantSelected.cant_empaq <= this.cantMAx(this.itemCantSelected.cant,this.itemCantSelected.disponible))){
                    let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
                    
                    await ApiServer.putCarritoCliente({cantidad:cant,id:this.carrito[index].id})
                    this.editarCantCarrito({index:index,cantidad:cant})
                }
                this.cargandoDetalle = false
                this.itemCantSelected = null
                
            } catch (error) {
                this.cargandoDetalle = false
                this.dialogCantidad = false
                this.itemCantSelected = null
                console.log(error);
            }
        },
        cerrarDialogCambiarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
            if(cant>=this.cantMAx(this.itemCantSelected.cant,this.itemCantSelected.disponible)){
                this.carrito[index].cantidad=this.cantMAx(this.itemCantSelected.cant,this.itemCantSelected.disponible) /  this.itemCantSelected.cant_empaq
            }
            if(cant<=0){
                this.carrito[index].cantidad = 1
            }
            this.dialogCantidad = false
            this.itemCantSelected = null
        },
        async confirmarTodo(){
            try {
                this.cargandoDetalle = true
                for(const item of this.detalleCpr){
                    if(!this.carrito.find(i => i.cod_art==item.COD_ART)){//SI NO ESTA EN EL CARRITO, LO AGREGO
                        if(item.CANT>0){
                            await this.agregar(item)
                        }
                    }else{
                        //SI ESTÁ, PERO ES DIFERENTE, LO EDITO
                        let element = this.carrito.find(i => i.cod_art==item.COD_ART)
                        let index =this.carrito.findIndex(i => i.cod_art==item.COD_ART)
                        if(item.CANT!=element.cantidad || this.itemSelected.CPR_ID!=element.cpr || item.FEC_CANCEL!=element.fec_cancel){
                            await ApiServer.putCarritoCliente({
                                id:element.id,
                                cantidad: (item.CANT)?item.CANT:1,
                                lpr_id:(item.LISTA_ID)?item.LISTA_ID:this.usuario.listas[0].LISTA_ID,
                                cpr:this.itemSelected.CPR_ID,
                                fec_cancel:(item.FEC_CANCEL)?moment(item.FEC_CANCEL).format('YYYY-MM-DD'):item.FEC_CANCEL
                            })
                            this.editarArtCarrito({
                                index:index,
                                cantidad: (item.CANT)?item.CANT:1,
                                lpr_id:(item.LISTA_ID)?item.LISTA_ID:this.usuario.listas[0].LISTA_ID,
                                cpr:this.itemSelected.CPR_ID,
                                fec_cancel:(item.FEC_CANCEL)?moment(item.FEC_CANCEL).format('YYYY-MM-DD'):item.FEC_CANCEL
                            })
                        }
                    }
                }
                this.cargandoDetalle = false
            } catch (error) {
                this.cargandoDetalle = false
              console.log(error);  
            }
        },
        presupuestoVencido(fec){
            if(fec){
                let hoy=moment().format('DD-MM-YYYY')
                let fecVenc = moment(fec).format('DD-MM-YYYY')
                let dif = moment(hoy,"DD-MM-YYYY").diff(moment(fecVenc,"DD-MM-YYYY"),'days')
                if(dif<=0){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }       
        },
        precioArt(item){
            let precio = null
            if(this.precioConIva){
                precio = item.PR_VTA + item.PR_VTA*(item.PORC_DR/100)
            }else{
                precio = item.PR_FINAL + item.PR_FINAL*(item.PORC_DR/100)
            }
            return Math.round(precio)
        },
        precioPorEmpaque(item){
            let precio = null
            if(this.precioConIva){
                let pr_vta= item.PR_VTA + item.PR_VTA*(item.PORC_DR/100)
                precio =  Math.round(pr_vta)*item.CANT_EMPAQ
            }else{
                let pr_final = item.PR_FINAL + item.PR_FINAL*(item.PORC_DR/100)
                precio =  Math.round(pr_final)*item.CANT_EMPAQ
            }
            return precio
        },
        precioTotalItem(item){
            let total = 0
            let empaque = (item.CANT_EMPAQ!=0)? item.CANT_EMPAQ : 1 
            let art = this.carrito.find(x=>x.cod_art==item.COD_ART)
            if(this.precioConIva){
                total = item.PR_VTA*empaque*art.cantidad+(art.descuento/100*item.PR_VTA*empaque*art.cantidad)

            }else{
                total = item.PR_FINAL*empaque*art.cantidad+(art.descuento/100*item.PR_FINAL*empaque*art.cantidad)
            }
            return total
        },
        cantMAx(cant,disponible){
            if(cant>=disponible){
                return cant
            }else{
                return disponible
            }
        }
    },
    async mounted(){
        try {
            console.log('carrito', this.carrito);
            this.cargando = true
            if(this.dialogDetalle == false && this.$route.hash=='#detalle'){
               this.$router.replace({ path: '/cotizaciones' })
            }
            let result = await ApiServer.getPresupuestoCliente(this.usuario.cod_cli)
            result.forEach(item => {
                item.FEC_EMISION = moment(item.FEC_EMISION).format('DD/MM/YYYY')
            });
            if(result.length>0){
               result = result.filter(x=>this.presupuestoVencido(x.FEC_CANCEL)==false)//SOLO MUESTRO LOS QUE NO ESTAN VENCIDOS
            }
            this.cotizaciones = result
            this.cargando = false
        } catch (error) {
            this.cargando = false
            console.log(error);
        }
    },
    computed:{
        ...mapState(['usuario','carrito']),
        precioConIva(){
            if(this.usuario.iva_id=='MO' || this.usuario.iva_id=='RI'){
                return true
            }else{
                return false
            }
        },
        validarCantidad(){
            let index = this.carrito.findIndex(i => i.cod_art==this.itemCantSelected.cod_art)
            if(index>=0){
                let cant = this.carrito[index].cantidad*this.carrito[index].cant_empaq
                if(cant>this.cantMAx(this.itemCantSelected.cant,this.itemCantSelected.disponible) || cant<=0){
                    return false
                }else{
                    return true
                } 
            }
        },
    },
    watch:{
        '$route.hash'(newHash,oldHash) {
            if(newHash === '#detalle') {
                this.dialogDetalle = true
            } else if (oldHash === '#detalle'){
                this.dialogDetalle = false
            }
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
    .cant.v-text-field--outlined >>> fieldset {
    border-color: blue-grey;
    border-width: 2px;
    height: 35px;
    align-self: start;
    margin-top: 6px
    }
</style>