<template>
  <v-app>
    <Error v-if="error"></Error>
    <!-- <Login v-if="!check && !error"></Login>  -->
    <Header v-if="this.$route.name=='Home' && !error && !cargando"></Header>
    <v-main v-if="!error && !cargando" >
       <router-view />
    </v-main>
    <Footer v-if="this.$route.name=='Home' && !error && !cargando"></Footer>
  </v-app>
</template>

<script>
import ApiServer from './api'
import Error from './components/Error.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import config from '../server/config/config.json'
import {mapActions, mapState} from 'vuex';
import moment from 'moment'
export default {
  name: 'App',
  components:{Header,Footer,Error},
  data(){
    return {
      error: false,
      cargando:true,
    }
  },
  methods:{
     ...mapActions(['datosUsuario','agregarAlCarrito','editDatosUsuario']),
    presupuestoVencido(fec){
        if(fec){
            let hoy=moment().format('DD-MM-YYYY')
            let fecVenc = moment(fec).format('DD-MM-YYYY')
            let dif = moment(hoy,"DD-MM-YYYY").diff(moment(fecVenc,"DD-MM-YYYY"),'days')
            if(dif<=0){
                return false
            }else{
                return true
            }
        }else{
            return true
        }       
    }
  },
  async mounted(){
    document.title = 'Unarsa'
    try {
        let urlParams = new URLSearchParams(window.location.search);
        //OBTENGO EL TOKEN DEL QUERY PARAMS
        let token = urlParams.get('token')
        if(token){//EXISTE EL TOKEN EN LA URL
            try {
                let datos = jwt.verify(token, config.jwt.TOKEN_SECRET)//VERIFICO LA VALIDEZ
                if (!datos) {
                this.error = true;
                window.location.href = "https://loguin.com.ar/?error=true"
                }else{
                //GENERO UN NUEVO TOKEN CON UNA DURACION MAS LARGA
                let nuevoToken = jwt.sign({
                    usuario: datos.usuario,
                    userId: datos.userId,
                    cod_cli: datos.cod_cli,
                    cli_id: datos.cli_id,
                    ctacorrentista:datos.ctacorrentista,
                    iva_id: datos.iva_id,
                    muestra_lista: datos.muestra_lista,
                    listas: datos.listas
                }, config.jwt.TOKEN_SECRET,{ expiresIn: '2h' })
                Cookies.set('token', nuevoToken)
                this.datosUsuario(datos)
                this.error = false
                }
            } catch (error) {
                if(error.name && error.name=="TokenExpiredError"){//SE VENCIO
                window.location.href = "https://loguin.com.ar"
                }else{
                window.location.href = "https://loguin.com.ar/?error=true"//ALGUN ERROR QUE NO ES DE VENCIMIENTO
                }
            }
        }else{//SI NO EXISTE EL TOKEN EL EL QUERY
            if(Cookies.get('token')){//ME FIJO SI TIENE EL TOKEN EN LAS COOKIES
                let token = Cookies.get('token')
                let datos = null
                try {
                datos = jwt.verify(token, config.jwt.TOKEN_SECRET)
                } catch (error) {

                if(error.name && error.name=="TokenExpiredError"){//SE VENCIO
                    Cookies.remove('token')//ELIMINO EL TOKEN VENCIDO
                    window.location.href = "https://loguin.com.ar"
                }else{
                    Cookies.remove('token')//ELIMINO EL TOKEN VENCIDO
                    window.location.href = "https://loguin.com.ar/?error=true"//ALGUN ERROR QUE NO ES DE VENCIMIENTO
                }
                }
                if(datos){
                this.error = false
                this.datosUsuario(datos)
                }
            }else{
                //EL TOKEN NO ESTA NI EN EL QUERY NI EN LA COOKIE
                window.location.href = "https://loguin.com.ar"
            }
        }
    //---------------------------------------------------------------------------
    //-------------------------------------------------------------------------
    // try {
    //   if(Cookies.get('token')){
    //     let token = Cookies.get('token')
    //     let datos = null
    //     try {
    //       datos = jwt.verify(token, config.jwt.TOKEN_SECRET)
    //     } catch (error) {

    //       if(error.name && error.name=="TokenExpiredError"){//SE VENCIO
    //         Cookies.remove('token')//ELIMINO EL TOKEN VENCIDO
    //         window.location.href = "https://loguin.com.ar"
    //       }else{
    //         Cookies.remove('token')//ELIMINO EL TOKEN VENCIDO
    //         window.location.href = "https://loguin.com.ar/?error=true"//ALGUN ERROR QUE NO ES DE VENCIMIENTO
    //       }
    //     }
    //     if(datos){
    //        this.error = false
    //        this.datosUsuario(datos)
    //     }
    //   }else{
    //      let urlParams = new URLSearchParams(window.location.search);
    //     //OBTENGO EL TOKEN DEL QUERY PARAMS
    //     let token = urlParams.get('token')
    //     if(!token){
    //       this.error = true;
    //        window.location.href = "https://loguin.com.ar"
    //     }else{
    //       try {
    //         let datos = jwt.verify(token, config.jwt.TOKEN_SECRET)//VERIFICO LA VALIDEZ
    //         if (!datos) {
    //           this.error = true;
    //           window.location.href = "https://loguin.com.ar/?error=true"
    //         }else{
    //           //GENERO UN NUEVO TOKEN CON UNA DURACION MAS LARGA
    //           let nuevoToken = jwt.sign({
    //             usuario: datos.usuario,
    //             userId: datos.userId,
    //             cod_cli: datos.cod_cli,
    //             cli_id: datos.cli_id,
    //             ctacorrentista:datos.ctacorrentista,
    //             iva_id: datos.iva_id,
    //             muestra_lista: datos.muestra_lista,
    //             listas: datos.listas
    //           }, config.jwt.TOKEN_SECRET,{ expiresIn: '2h' })
    //           Cookies.set('token', nuevoToken)
    //           this.datosUsuario(datos)
    //           this.error = false
    //         }
    //       } catch (error) {
    //         if(error.name && error.name=="TokenExpiredError"){//SE VENCIO
    //           window.location.href = "https://loguin.com.ar"
    //         }else{
    //           window.location.href = "https://loguin.com.ar/?error=true"//ALGUN ERROR QUE NO ES DE VENCIMIENTO
    //         }
    //       }
    //     }
    //   }
        if (urlParams.has('token')) {
            window.location.href = window.location.origin + window.location.pathname;
        }
        //CARGO MÁS DATOS DEL USUARIO
        try {
            let result = await ApiServer.getAplicacionUsuario(this.usuario.userId)

            if (result.length > 0) {
                this.editDatosUsuario(['celular', result[0].telefono])
                this.editDatosUsuario(['muestraCatalogoPuntos', result[0].muestra_catalogo_puntos])
                this.editDatosUsuario(['nombre', result[0].Nombre])
                this.editDatosUsuario(['apellido', result[0].Apellido])
                this.editDatosUsuario(['email', result[0].email])
                this.editDatosUsuario(['nombreUsuario', result[0].usuario])
                this.editDatosUsuario(['cod_cli_usuario', result[0].cod_cli_usuario])
                this.editDatosUsuario(['muestra_mis_canjes', result[0].muestra_mis_canjes])

            }
        } catch (error) {
            console.log(error);
        }
        this.cargando = false


        try {//CARGAR CARRITO
            let resultCarrito = await ApiServer.getCarritoCliente(this.usuario.cod_cli)
            let etiquetas = await ApiServer.getEtiquetasProductos()
            for(const item of resultCarrito){
                let resultArt = await ApiServer.getArtLpr(item.cod_art,item.lpr_id,this.usuario.cli_id)
                if(resultArt.length>0){
                    if(resultArt[0].DISPONIBLE>0 || !this.presupuestoVencido(item.fec_cancel)){//SI NO HAY STOCK NO LO AGREGO AL CARRITO, SALVO QUE SEA DE PRESUPUESTO
                        // console.log(resultArt);
                        let etiqueta = null
                        let indexEtiqueta = etiquetas.findIndex(x=>x.cod_art==item.cod_art)
                        if(indexEtiqueta>-1)
                        etiqueta = `https://loguin.com.ar/img/imag_etiquetas/${etiquetas[indexEtiqueta].etiqueta}?t=${(new Date()).getTime()}` 
                        //SI EL COMPROBANTE NO ESTA VENCIDO, LE MANTENGO EL PRECIO
                        if(!this.presupuestoVencido(item.fec_cancel)){
                            //BUSCO EL PRECIO EN EL CPRDET
                            let cprdet = await ApiServer.getCprDet(item.cpr,item.cod_art)
                            if(cprdet.length>0){
                                if(cprdet[0].PR_VTA) {
                                    resultArt[0].PR_VTA = cprdet[0].PR_VTA
                                    // resultArt[0].DESCUENTO = (100-(cprdet[0].PR_VTA*100/resultArt[0].PR_VTA)).toFixed(2)
                                }
                                if(cprdet[0].PR_FINAL){
                                    resultArt[0].PR_FINAL = cprdet[0].PR_FINAL
                                }
                                resultArt[0].DESCUENTO = cprdet[0].PORC_DR //ESTE DESCUENTO PISA AL QUE VIENE DE LA CONSULTA DEL ARTICULO
                                resultArt[0].CANT = cprdet[0].CANT //LA CANTIDAD EN EL PRESUPUESTO 
                            }
                        } 
                        // else if(resultArt[0].DESCUENTO && resultArt[0].DESCUENTO>0){// SI ESTA VENCIDO EL CPR, ME FIJO SI HAY DESCUENTO
                        //     resultArt[0].PR_FINAL = resultArt[0].PR_FINAL - (resultArt[0].PR_FINAL * (resultArt[0].DESCUENTO/100))
                        //     resultArt[0].PR_VTA = resultArt[0].PR_VTA - (resultArt[0].PR_VTA * (resultArt[0].DESCUENTO/100))
                        // }
                        this.agregarAlCarrito({
                            id:item.id,
                            lpr_id:item.lpr_id,
                            cod_art:item.cod_art,
                            art_id:resultArt[0].ART_ID,
                            desc_cliente: resultArt[0].DESC_CLIENTE,
                            descripcion: resultArt[0].DESCRIPCION,
                            mod:resultArt[0].MOD,
                            med:resultArt[0].MED,
                            desc_if:resultArt[0].DESC_IF,
                            cantidad:item.cantidad,
                            escala_id:resultArt[0].ESCALA_ID,
                            escala:resultArt[0].ESCALA,
                            esc:resultArt[0].ESC,
                            esc_plu:resultArt[0].ESC_PLU,
                            pr_vta:resultArt[0].PR_VTA,
                            pr_cto_cpra:resultArt[0].PR_CTO_CPRA,
                            pr_cto:resultArt[0].PR_CTO,
                            pr_final:resultArt[0].PR_FINAL,
                            descuento:resultArt[0].DESCUENTO,
                            disponible:resultArt[0].DISPONIBLE,
                            cant_empaq:resultArt[0].CANT_EMPAQ,
                            ord_rec_str:resultArt[0].ORD_REC_STR,
                            ean:resultArt[0].EAN,
                            imagen : `https://loguin.com.ar/img/${resultArt[0].ART_ID}.jpg`,
                            etiqueta: etiqueta,
                            cpr:item.cpr,
                            fec_cancel: item.fec_cancel
                        })

                    }
                }
            }
        } catch (error) {
            console.log(error);
        }

    } catch (error) {
      //Cookies.remove('token')
      //window.location.href = "https://loguin.com.ar/?error=true"
      this.cargando = false
      this.error = true
      console.log(error);
    }
  },
  computed: {
    ...mapState(['usuario','carrito'])
    // check: function(){
    //   try {
    //     let token = Cookies.get('user_token')
    //     let datos = jwt.verify(token, config.jwt.TOKEN_SECRET)
    //     if (!token || !datos) {
    //       return false;
    //     }else{
    //       this.datosUsuario(datos)
    //       return true;
    //     }
    //   } catch (error) {
    //     return false
    //   }
    // },
  }
};
</script>
<style>
::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}
::-webkit-scrollbar-thumb {
  background: #858585; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.azulUnarsa{
  color: #607d8b;
}
</style>
