<template>
    <v-footer app :color=this.$azulUnarsa rounded="0">
    </v-footer>
</template>

<script>

export default {
  name: 'Footer',

  data: () => ({
    //
  }),
};
</script>